var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import globalCartFragment from "../globalCartFragment.graphql";
export default (function (item) { return ({
    mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation removeFromCartMutation($input: RemoveFromCartInput!) {\n      removeFromCart(input: $input) {\n        cart {\n          id\n          extraPersonalFields\n          ...globalCart\n        }\n      }\n    }\n    ", "\n  "], ["\n    mutation removeFromCartMutation($input: RemoveFromCartInput!) {\n      removeFromCart(input: $input) {\n        cart {\n          id\n          extraPersonalFields\n          ...globalCart\n        }\n      }\n    }\n    ", "\n  "])), globalCartFragment),
    variables: { input: { item: item } },
}); });
var templateObject_1;
