var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
export default (function (input) { return ({
    mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation applyDiscountCodeMutation($input: ApplyDiscountCodeInput!) {\n      applyDiscountCode(input: $input) {\n        cart {\n          id\n          discount {\n            amount\n            currency\n          }\n          total {\n            amount\n            currency\n          }\n          discountCode {\n            code\n          }\n        }\n      }\n    }\n  "], ["\n    mutation applyDiscountCodeMutation($input: ApplyDiscountCodeInput!) {\n      applyDiscountCode(input: $input) {\n        cart {\n          id\n          discount {\n            amount\n            currency\n          }\n          total {\n            amount\n            currency\n          }\n          discountCode {\n            code\n          }\n        }\n      }\n    }\n  "]))),
    variables: { input: input },
}); });
var templateObject_1;
