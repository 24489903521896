var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import gql from "graphql-tag";
import { mapState } from "vuex";
import globalCartFragment from "../../../api/globalCartFragment.graphql";
import find from "lodash/find";
import addToOrCreateCart from "../../../api/addToOrCreateCart";
import mixins from "vue-typed-mixins";
import NotificationsMixin from "../../ui/NotificationsMixin";
import removeFromCart from "../../../api/mutations/removeFromCart";
import applyDiscountCode from "../../../api/mutations/applyDiscountCode";
import AsyncStatus from "../../../utils/async";
import { moneyFormat } from "../../../utils/filters";
export default mixins(NotificationsMixin).extend({
    data: function () {
        return {
            enteredDiscountCode: "",
            discountCodeStatus: new AsyncStatus(),
        };
    },
    apollo: {
        flatRateShippingPrice: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        flatRateShippingPrice {\n          amount\n          currency\n        }\n      }\n    "], ["\n      {\n        flatRateShippingPrice {\n          amount\n          currency\n        }\n      }\n    "]))),
        cart: {
            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        query CartQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            ...globalCart\n          }\n        }\n        ", "\n      "], ["\n        query CartQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            ...globalCart\n          }\n        }\n        ", "\n      "])), globalCartFragment),
            variables: function () {
                return {
                    id: this.cartId,
                };
            },
            skip: function () {
                return !this.cartId;
            },
        },
    },
    computed: __assign({ formattedFlatRateShippingPrice: function () {
            if (!this.flatRateShippingPrice) {
                return undefined;
            }
            return moneyFormat(this.flatRateShippingPrice);
        } }, mapState(["cartId"])),
    methods: {
        onSubmitDiscountCode: function () {
            var _this = this;
            if (!this.cart) {
                throw new Error("Invalid state");
            }
            this.discountCodeStatus
                .run(this.$apollo
                .mutate(applyDiscountCode({
                cart: this.cart.id,
                code: this.enteredDiscountCode,
            }))
                .then(function () {
                _this.enteredDiscountCode = "";
                _this.displaySuccessMessage(_this.$t("Discount code applied"));
            })
                .catch(function (e) {
                var error = find(e.graphQLErrors || [], function (error) { return "code" in error; });
                if (!error) {
                    throw new Error("Couldn't add discount code");
                }
                throw new Error(error.code.join(", "));
            }))
                .catch(function (e) { return console.log(e); });
        },
        addToCart: function (product) {
            addToOrCreateCart(this.$apollo, this.$store, this.$t.bind(this), product);
        },
        removeCartItem: function (item) {
            this.$apollo.mutate(removeFromCart(item));
        },
    },
});
var templateObject_1, templateObject_2;
